/* eslint-disable no-plusplus */
import React, { Suspense, lazy, useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { SpinnerDotted } from 'spinners-react';
import { Image } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REACT_APP_API_FETCH_EVENT_VENDORS } from '../../redux/apiConstants';
// import Menu from '../../components/LeftMenu/DashMenu';
import { Entry } from '../../redux/entrySlice';
import styles from './dashboard.module.scss';
import { TableContext } from '../../contexts/tableContext';
import { NumFormatter } from '../../utilityFunctions/utilsFunctions';
// import DashFooter from '../../components/Footer/DashFooter';
// import event from '../../assets/no_events.png';
import noactiveevent from '../../assets/noactiveimage.svg';

import CustomTable from '../../components/CustomTable/CustomTable';

const Counter1 = lazy(() => import('../../components/Counter1/Counter'));
const Counter2 = lazy(() => import('../../components/Counter2/Counter'));
const Counter3 = lazy(() => import('../../components/Counter3/Counter'));
const Counter4 = lazy(() => import('../../components/Counter4/Counter'));
const Counter5 = lazy(() => import('../../components/Counter5/Counter'));
// const AgGridTable = lazy(() => import('../../components/AgTable/AgGridTable'));

export default function EventHome() {
  const {
    dashboardStyle,
    setUrl,
    bodyStyle,
    card1bgStyle,
    card2bgStyle,
    card3bgStyle,
    card4bgStyle,
    setDashboardHeader,
  } = useContext(TableContext);
  const [vendorCount, setVendorCount] = useState(0);
  const [activeEvent, setActiveEvent] = useState();
  const [totalTokens, setTotalTokens] = useState(0);
  const [totalUserTokens, setTotalUserTokens] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [tokensPerDay, setTokensPerDay] = useState(0);
  const [noEvent, setNoEvent] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setDashboardHeader('');
    setUrl(REACT_APP_API_FETCH_EVENT_VENDORS);
    const actionData = {};
    actionData.actionUrl = 'eventdashboard';
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData))
      .then((resp) => {
        if (resp.payload.code === 200) {
          setLoading(false);
          const month = [];
          const data = [];
          for (let i = 0; i < resp.payload.length; i++) {
            month.push(resp.payload[i].month);
            data.push(resp.payload[i].count);
          }
          setVendorCount(resp.payload.data.vendorCount);
          setTotalTokens(resp.payload.data.totalTokenCount);
          setTotalUserTokens(resp.payload.data.userTokens);
          setTotalRevenue(resp.payload.data.totalAmount);
          setTokensPerDay(resp.payload.data.tokensPerDay);
          setActiveEvent(resp.payload.data.currentEvent);
        } else if (resp.payload.code === 404) {
          setLoading(false);
          setNoEvent(true);
        }
      })
      .catch(() => toast.error('Something went wrong!!'));
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={`${styles.dashboardContain} ${styles[dashboardStyle]}`}
        id={styles.dashboardcont2}
      >
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <SpinnerDotted
              style={{
                color: '#39979d',
                height: '10vh',
              }}
            />
          </div>
        ) : (
          <div>
            {noEvent ? (
              <div className="col-md-12">
                <div className={styles.no_evnt}>
                  <Image src={noactiveevent} alt="gggf" />
                  <h6>There is no active event.</h6>
                  <button
                    className="addBtn mb-20"
                    type="button"
                    onClick={() => navigate('/event/create')}
                  >
                    <svg
                      width={18}
                      height={18}
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      className="svg-inline--fa fa-plus add_icon"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                      />
                    </svg>
                    Add Event
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <ul className={`${styles.cardsection} row`}>
                    <Suspense
                      fallback={
                        <div>
                          <SpinnerDotted
                            style={{ marginTop: '20px', marginLeft: '120px', color: '#39979d' }}
                          />
                        </div>
                      }
                    >
                      <Counter1
                        className={styles[card1bgStyle]}
                        cardstyle={styles.cardbox1}
                        text="Total Tokens Purchased"
                        count={NumFormatter(totalUserTokens)}
                      />
                    </Suspense>
                    <Suspense
                      fallback={
                        <div>
                          <SpinnerDotted
                            style={{ marginTop: '-70px', marginLeft: '420px', color: '#39979d' }}
                          />
                        </div>
                      }
                    >
                      <Counter5
                        className={styles[card2bgStyle]}
                        cardstyle={styles.cardbox2}
                        text="Total Revenue"
                        count={`$${NumFormatter(totalRevenue)}`}
                      />
                    </Suspense>
                    <Suspense
                      fallback={
                        <div>
                          <SpinnerDotted
                            style={{ marginTop: '-120px', marginLeft: '700px', color: '#39979d' }}
                          />
                        </div>
                      }
                    >
                      <Counter3
                        className={styles[card3bgStyle]}
                        cardstyle={styles.cardbox3}
                        text="Total Tokens Collected"
                        count={NumFormatter(totalTokens)}
                      />
                    </Suspense>
                    <Suspense
                      fallback={
                        <div>
                          <SpinnerDotted
                            style={{ marginTop: '-150px', marginLeft: '1000px', color: '#39979d' }}
                          />
                        </div>
                      }
                    >
                      <Counter2
                        className={styles[card4bgStyle]}
                        cardstyle={styles.cardbox4}
                        text="Tokens Collected Today"
                        count={NumFormatter(tokensPerDay)}
                      />
                    </Suspense>
                    <Suspense
                      fallback={
                        <div>
                          <SpinnerDotted
                            style={{ marginTop: '-150px', marginLeft: '1000px', color: '#39979d' }}
                          />
                        </div>
                      }
                    >
                      <Counter4
                        className={styles[card4bgStyle]}
                        cardstyle={styles.cardbox5}
                        text="Vendors"
                        count={NumFormatter(vendorCount)}
                      />
                    </Suspense>
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {!noEvent && (
        <div className={styles.tablesection}>
          {vendorCount && (
            <CustomTable
              urlParam={REACT_APP_API_FETCH_EVENT_VENDORS}
              section="eventdashboard"
              dashTitle={
                activeEvent?.status === 'created'
                  ? 'Upcoming Event Vendors'
                  : 'Current Event Vendors'
              }
            />
          )}
        </div>
      )}
    </div>
  );
}
