/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { SERVER_BASE_URL } from '../../redux/apiConstants';
import { getCookies } from '../../hooks/useCookies';

function ExportToExcel({ apiUrl, fileName }) {
  const token = `Bearer ${getCookies('Token')}`;
  const exportToCSV = (appendUrl, filename) => () => {
    const url = `${SERVER_BASE_URL}/${appendUrl}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.code === 400) {
          toast.error("'Currently there is no active event.");
        } else {
          // eslint-disable-next-line no-underscore-dangle
          const cleanArray = resp.data.result.results.map((item) => {
            const { name, businessName, email, phone, wallet, active } = item;
            return {
              Vendor_Name: name,
              Stall_Name: businessName,
              Email: email,
              Contact_Number: phone,
              Tokens_Received: wallet?.tokensLeft,
              Status: active ? 'Active' : 'Inactive',
            };
          });
          const ws = XLSX.utils.json_to_sheet(cleanArray);
          const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
          XLSX.writeFile(wb, `${filename}.xlsx`);
        }
      })
      .catch((e) => toast.error('Currently there is no active event.')); // setting response to state posts
  };
  return (
    // <li>
    //   <a className="dropdown-item" onClick={exportToCSV(apiUrl, fileName)}>
    //     Export Excel
    //   </a>
    // </li>
    <button className="addBtnyellow mb-20" type="button" onClick={exportToCSV(apiUrl, fileName)}>
      {' '}
      <FontAwesomeIcon icon={faDownload} className="add_icon" />
      Download
    </button>
  );
}

ExportToExcel.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ExportToExcel;
