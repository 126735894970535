import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import styles from './PhoneInput.module.scss';

function PhoneInputBox({
  placeHolder,
  fieldName,
  className,
  labelstyle,
  label,
  control,
  errors,
  labeldarkstyle,
  classNamedark,
  mandatory,
  defaultValue,
  handleChange,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }

  return (
    <div className="form-group">
      <label className={classNames ?? labelstyle} htmlFor="phone-input">
        {label}
      </label>
      {mandatory ? <span style={{ color: 'red', marginLeft: '3px' }}>*</span> : <span />}
      <Controller
        name={fieldName}
        control={control}
        rules={{
          validate: (value) =>
            value && isValidPhoneNumber(value) ? true : 'Invalid phone number.',
        }}
        render={() => (
          <PhoneInput
            className={styles[classNamess ?? className]}
            countries={['US', 'IN']}
            value={defaultValue}
            onChange={(event) => {
              handleChange(event);
            }}
            defaultCountry="US"
            id="phone-input"
            limitMaxLength
            addInternationalOption={false}
            placeholder={placeHolder}
          />
        )}
      />
      {errors[fieldName] && (
        <p
          style={{
            color: 'red',
            marginTop: '5px',
            fontSize: '14px',
          }}
        >
          {' '}
          {
            // Shows if error exist
            errors[fieldName] && errors[fieldName].message
          }{' '}
        </p>
      )}
    </div>
  );
}

PhoneInputBox.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  control: PropTypes.element.isRequired,
  labelstyle: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  errors: PropTypes.element.isRequired,
  handleChange: PropTypes.func.isRequired,
  labeldarkstyle: PropTypes.string.isRequired,
  classNamedark: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  mandatory: PropTypes.bool,
};

PhoneInputBox.defaultProps = {
  mandatory: false,
  defaultValue: '',
};

export default PhoneInputBox;
