import * as yup from 'yup';

const tokenTransferValidations = {
  phone: yup
    .string()
    .required('Phone Number is required.')
    .min(10, 'Phone Number must be at least 10 characters long.')
    .max(13, 'Phone Number cannot be more than 13 characters long.'),
  tokens: yup.string().required('Token Amount is required.'),
};

export default tokenTransferValidations;
