/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { getCookies } from '../../hooks/useCookies';
import styles from './ToggleButton.module.scss';
import envValues from '../../enviornment';
import { TableContext } from '../../contexts/tableContext';
import ConfirmAlertPopup from '../ConfirmAlertPopUp/ConfirmAlert';

function ToggleButtonOnOff({ value, id, section }) {
  const [isOff, setIsOff] = useState(false);
  const { setActivate } = useContext(TableContext);
  const token = getCookies('Token');
  const [showConfirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    setIsOff(value);
  }, [value]);

  const handleButtonClick = () => {
    if (section === 'vendor' || section === 'vendorusers') {
      setIsOff(!isOff);
      setConfirmModal(false);
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        // Authorization: `Bearer ${token}`,
      };
      const body = { active: !isOff };
      axios
        .post(`${envValues.REACT_APP_API_ENDPOINT}/vendor/toggle-change/${id}`, body, headers)
        .then(() => {
          setActivate(id);
        });
    } else {
      setIsOff(!isOff);
      setConfirmModal(false);
      const headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
      };
      const body = { token: getCookies('Token'), userid: id, active: !isOff };
      axios
        .post(`${envValues.REACT_APP_API_ENDPOINT}/auth/toggle-change`, body, headers)
        .then(() => {});
    }
  };

  function confirmation() {
    setConfirmModal(true);
  }

  const handleLogoutClose = () => setConfirmModal(false);

  return (
    <div className="col ">
      <ConfirmAlertPopup
        show={showConfirmModal}
        handleClose={handleLogoutClose}
        onConfirm={handleButtonClick}
        onCancel={handleLogoutClose}
      />
      <label className={styles.switch}>
        <input type="checkbox" checked={isOff} onClick={confirmation} />
        <span className={styles.slider} />
      </label>
    </div>
  );
}

ToggleButtonOnOff.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  section: PropTypes.string,
};
ToggleButtonOnOff.defaultProps = {
  value: false,
  id: null,
  section: 'User',
};
export default ToggleButtonOnOff;
