/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from './index.module.scss';
import { REACT_APP_API_FETCH_TRANSFER } from '../../redux/apiConstants';
import TransferTable from '../../components/TokenTransferTable/CustomTable';
// import { TableContext } from '../../contexts/tableContext';

const TransferIndex = React.memo(() => {
  // const { dashboardStyle } = useContext(TableContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.indexdiv}>
      <TransferTable
        urlParam={REACT_APP_API_FETCH_TRANSFER}
        section="transfer"
        dashTitle="Token Transfer"
        toggleButton={{ show: true, field: 'active' }}
        deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
      />
    </div>
  );
});
export default TransferIndex;
