import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { TableContext } from '../../contexts/tableContext';
import styles from './settings.module.scss';

function SettingsLayout() {
  const { dashboardStyle } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);

  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]} id={styles.dashboardcont2}>
        <div className={styles.settingsection}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SettingsLayout;
