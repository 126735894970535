/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableViewPopup from '../TableViewPopup/TableViewPopup';
import styles from './ActionButtons.module.scss';
import useToggle from '../../hooks/useToggle';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import { getCookies } from '../../hooks/useCookies';
import eyeIcon from '../../assets/eye_icon.svg';
import DeleteModal from '../DeleteModal/DeleteModal';

function ActionButtons({ data, section, viewPopUp, deleteCondition, pageNum, setDeleteSuccess }) {
  const { actionbtnStyle, setPageNumView } = useContext(TableContext);
  const [actionPermissions, setActionPermission] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setDeletemessage] = useState();
  const deleteMessage = `Are you sure, you want to delete ${data.name} ?`;
  const [canDelete, setCanDelete] = useState(true);
  const [showTablePopUp, setShowTablePopUp] = useToggle();
  const [showDeleteModal, setDeleteModal] = useState(false);

  const actionView = (param) => () => {
    const encodeText = param.id;
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      setPageNumView(pageNum);
      navigate(`/${section}/vendor/${encodeText}`);
    }
  };

  const actionEdit = (params) => {
    const encodeText = params.id;
    setPageNumView(pageNum);
    navigate(`/${section}/edit/${encodeText}`);
  };

  const confirmDelete = () => {
    if (canDelete) {
      const actionData = {};
      actionData.actionUrl = `${section.toLowerCase()}/${data.id}`;
      actionData.actionMethod = 'delete';
      dispatch(Entry(actionData)).then(() => {
        if (section === 'vendorusers') {
          toast.success('Vendor deleted successfully.');
        } else if (section === 'attendee') {
          toast.success('Attendee deleted successfully.');
        } else if (section === 'manager') {
          toast.success('Manager deleted successfully.');
        } else if (section === 'appupdate') {
          toast.success('App update deleted successfully.');
        } else {
          toast.success('Row deleted successfully.');
        }
        setDeleteSuccess(data.id);
        setDeleteModal(false);
      });
    }
  };

  function handleDelete() {
    setDeleteModal(true);
  }

  const handleDeleteClose = () => setDeleteModal(false);

  useEffect(() => {
    const temp = section.replace(/\s+/g, '').toLowerCase();
    const permissions = getCookies('USERPERMISSION');
    permissions.forEach((val) => {
      if (val.section.toLowerCase() === temp) {
        setActionPermission({
          view: val.view ? val.view : false,
          edit: val.edit ? val.edit : false,
          create: val.create ? val.create : false,
          delete: val.delete ? val.delete : false,
        });
      }
    });
    if (deleteCondition.checkCondition) {
      setCanDelete(false);
      setDeletemessage('Sorry you cant delete this data');
    }
  }, []);

  return (
    <>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" id={styles.btnflex}>
        {actionPermissions.view && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            onClick={actionView(data)}
          >
            {' '}
            <i className="fa fa-eye" />
            {/* <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.8729 6.61109C19.6942 6.36667 15.4371 0.626465 9.9999 0.626465C4.56274 0.626465 0.305391 6.36667 0.126914 6.61085C-0.0423048 6.84273 -0.0423048 7.15722 0.126914 7.3891C0.305391 7.63351 4.56274 13.3737 9.9999 13.3737C15.4371 13.3737 19.6942 7.63347 19.8729 7.38929C20.0423 7.15746 20.0423 6.84273 19.8729 6.61109ZM9.9999 12.055C5.99486 12.055 2.52606 8.24515 1.49922 6.99964C2.52473 5.75304 5.98626 1.94514 9.9999 1.94514C14.0048 1.94514 17.4733 5.75437 18.5006 7.00054C17.4751 8.24711 14.0135 12.055 9.9999 12.055Z"
                fill="#98999E"
              />
              <path
                d="M10 3.04395C7.81868 3.04395 6.04395 4.81868 6.04395 7.00001C6.04395 9.18134 7.81868 10.9561 10 10.9561C12.1813 10.9561 13.9561 9.18134 13.9561 7.00001C13.9561 4.81868 12.1813 3.04395 10 3.04395ZM10 9.63736C8.54571 9.63736 7.36266 8.45427 7.36266 7.00001C7.36266 5.54575 8.54575 4.36266 10 4.36266C11.4543 4.36266 12.6374 5.54575 12.6374 7.00001C12.6374 8.45427 11.4543 9.63736 10 9.63736Z"
                fill="#98999E"
              />
            </svg> */}
          </button>
        )}
      </div>
      {showTablePopUp ? (
        <TableViewPopup
          dataId={data.id}
          handleClose={setShowTablePopUp}
          section={section.toLowerCase()}
        />
      ) : null}
      <DeleteModal
        show={showDeleteModal}
        context={section === 'vendorusers' ? 'vendor' : section === 'attendee' ? 'user' : section}
        handleClose={handleDeleteClose}
        onConfirm={confirmDelete}
        onCancel={handleDeleteClose}
      />
    </>
  );
}

ActionButtons.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  viewPopUp: PropTypes.bool.isRequired,
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  pageNum: PropTypes.number,
  setDeleteSuccess: PropTypes.func.isRequired,
};

ActionButtons.defaultProps = {
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
  pageNum: 1,
};

export default ActionButtons;
