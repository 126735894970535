/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { SpinnerDotted } from 'spinners-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TableContext } from '../../contexts/tableContext';
import { tabledelete } from '../../redux/TableDeleteSlice';
import ToggleButtonOnOff from '../ToggleButton/ToggleButton';
import styles from './EventCustomTable.module.scss';
import { getCookies } from '../../hooks/useCookies';
import searchImg from '../../assets/search.svg';
import Paginations from '../Pagination/Paginations';
import DeleteModal from '../DeleteModal/DeleteModal';
import { DateFormatter } from '../../utilityFunctions/utilsFunctions';
import EventActionButtons from '../ActionsButtons/EventActionButtons';
import event from '../../assets/no_events.png';
import defaultImg from '../../assets/defaultEventImg.png';
import noresults from '../../assets/noresults.svg';

const EventTable = React.memo(
  ({ urlParam, viewPopUp, toggleButton, section, formatter, deleteCondition, dashTitle }) => {
    let url;
    const { dashboardStyle } = useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const { pageNumView, setPageNumView } = useContext(TableContext); // for controlling view or edit backbutton
    const [pageNum, setPageNum] = useState(1);
    // new
    const tablelength = tableData.length;
    const [checkedState, setCheckedState] = useState(new Array(tablelength).fill(false));
    const [checkedAll, setCheckedAll] = useState(false);
    const token = `Bearer ${getCookies('Token')}`;
    const [sorting, setSorting] = useState([{ col: 'none', sortType: 'none' }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    const [tableConfig, setTableConfig] = useState();
    const [deletedData, setDeletedData] = useState(null);
    const [selected, setselected] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [deleteSuccess, setDeleteSuccess] = useState();
    const [activeEvent, setActiveEvent] = useState(false);
    const [loading, setLoading] = useState(true);
    const dataPerPage = 10;
    let response;
    let statusCode;

    const handleOnChange = (id, position) => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      const updatedCheckedState = checkedState.map((item, indexed) =>
        indexed === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      setselected((oldArray) => [...oldArray, id]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= checkedState.length; i++) {
        if (checkedState[position] === true) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < selected.length; j++) {
            if (selected[j] === id) {
              selected.splice(j, 1);
            }
            // const newselected = selected.filter((item) => item === id);
            // setselected(newselected);
          }
        }
      }
    };

    // const activeEvent = {
    //   image: 'https://via.placeholder.com/150', // Replace with actual event image URL
    //   title: 'Sample Event Title',
    //   date: 'August 20, 2024',
    //   description:
    //     'This is a brief description of the active event. It provides key details about the event and what to expect.',
    // };

    const actionAdd = () => {
      navigate(`/${section.toLowerCase()}/create/`);
    };

    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };

    const getUsers = async () => {
      setTableLoading(true);
      url = urlParam;
      url += `page=${pageNumView > 1 ? pageNumView : pageNum}&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== 'none') {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        url += `&searchBy=${search}`;
      }
      axios
        .get(url, {
          method: 'GET',
          headers: { Authorization: token },
        })
        .then((resp) => {
          setTableLoading(false);
          statusCode = resp?.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200) {
            setLoading(false);
            response = res?.data?.data?.result;
            setTableData(response?.results);
            setActiveEvent(res?.data?.data?.activeEvent);
            setPaginationData({
              page: response?.page,
              totalResult: response?.totalResults,
              totalPages: response?.totalPages,
            });
            setPageNum(response?.page);
            const { Displayfields } = response;
            if (Array.isArray(Displayfields)) {
              setColumns(Displayfields);
            } else {
              const colkeys = Object.keys(Displayfields);
              setTableConfig(Displayfields);
              setColumns(colkeys);
            }
          }
        })
        .catch((err) => {
          setTableLoading(false);
          setLoading(false);
          return err;
        });
      if (pageNumView > 1) setPageNumView(1);
    };

    function formatTimeRange(timeRange) {
      if (typeof timeRange !== 'string' || timeRange.trim() === '') {
        return '';
      }
      const [startTime, endTime] = timeRange.split(' - ');

      function formatTime(time) {
        if (typeof time !== 'string' || time.trim() === '') {
          return '';
        }
        const [hour, minute] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute?.toString().padStart(2, '0')} ${period}`;
      }

      return `${formatTime(startTime)} - ${formatTime(endTime)}`;
    }

    const formatTime = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      let period = 'AM';

      let adjustedHours = hours;
      if (hours >= 12) {
        period = 'PM';
        adjustedHours = hours > 12 ? hours - 12 : hours;
      }
      if (hours === 0) {
        adjustedHours = 12;
      }

      const formattedTime = `${adjustedHours}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`;
      return formattedTime;
    };

    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, []);

    useEffect(() => {
      if (dashTitle) setDashboardHeader('');
      else setDashboardHeader(section);
      const temp = section.replace(/\s+/g, '').toLowerCase();
      const permissions = getCookies('USERPERMISSION');
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              setAddButtonPermissions({
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(element.view || element.edit || element.create || element.delete),
              });
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    }, [pageNum, search, sorting, deletedData, deleteSuccess]);

    const setSort = (param) => () => {
      setPageNum(1);
      switch (sorting.sortType) {
        case 'none':
          setSorting({ col: param, sortType: 'asc' });
          break;
        case 'asc':
          setSorting({ col: param, sortType: 'desc' });
          break;
        case 'desc':
          setSorting({ col: param, sortType: 'none' });
          break;
        default:
          setSorting({ col: param, sortType: 'asc' });
          break;
      }
    };

    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.length > 0) {
            setPageNum(1);
            setSearchTerm(even.target.value.trim());
          } else {
            setSearchTerm(null);
          }
        }, 1000)
      );
    };

    return (
      <div className={styles[bodyStyle]}>
        {/* DELETE MODAL */}

        {addButtonPermissions.list && (
          <div
            className={styles[dashboardStyle]}
            style={{ minHeight: '100vh' }}
            id={styles.dashboardcont2}
          >
            <div className="container-fluid" id={styles.container}>
              {!loading && (
                <h5 className={styles.title}>
                  {activeEvent?.status === 'created' ? <b>Upcoming Event</b> : <b>Current Event</b>}
                </h5>
              )}
              {loading ? null : (
                <div>
                  {activeEvent ? (
                    <div
                      className={styles.activeEventCard}
                      onClick={() => navigate(`/event/viewdetails/${activeEvent.id}`)}
                    >
                      <img
                        src={activeEvent.image || defaultImg}
                        alt="Event"
                        className={styles.eventImage}
                      />
                      <div className={styles.eventDetails}>
                        <Row>
                          <Col lg={6}>
                            <p className={styles.eventDescription}>Event Name</p>
                            <h3 className={styles.eventTitle}>{activeEvent.eventName}</h3>
                            <p className={styles.eventDescription}>Location</p>
                            <h3 className={styles.eventTitle}>{activeEvent.location?.venue},</h3>
                            <p className={styles.eventDescription1}>
                              {activeEvent.location?.street}, {activeEvent.location?.city}
                            </p>
                          </Col>
                          <Col lg={6}>
                            <p className={styles.eventDescription}>Date & Time</p>
                            <p className={styles.eventTitle}>
                              {DateFormatter(activeEvent?.startDate, 'toEventDate')} -{' '}
                              {DateFormatter(activeEvent?.endDate, 'toEventDate')}
                            </p>
                            <p className={styles.eventTitle}>{`${formatTime(
                              activeEvent.startTime
                            )} - ${formatTime(activeEvent?.endTime)}`}</p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12">
                      <div className={styles.no_evnt}>
                        <Image src={event} alt="gggf" />
                        <h6>There is no event.</h6>
                        <button
                          className="addBtn mb-20"
                          type="button"
                          onClick={() => {
                            actionAdd();
                          }}
                        >
                          <svg
                            width={18}
                            height={18}
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="plus"
                            className="svg-inline--fa fa-plus add_icon"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                            />
                          </svg>
                          Add Event
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!loading && (
                <div className="row mt-3" style={{ alignItems: 'center' }}>
                  <div className="col-lg-6">
                    <h5>
                      <b>Past Events</b>
                    </h5>
                  </div>
                  <div className="col-lg-6">
                    <div className="table_top1">
                      <div className="search_contain mb-20">
                        <Image src={searchImg} alt="search" />
                        <input
                          className={`${styles[searchboxStyle]} searchbar`}
                          type="search"
                          onChange={onFilterTextChange}
                          placeholder="Search"
                          // value={search}
                          readOnly={tableLoading}
                        />
                        <div
                          className={tableLoading ? 'spinner-border spinner-border-sm' : ''}
                          id={styles.spinner}
                          role="status"
                        />
                      </div>
                      <div className={styles.sectionright}>
                        {addButtonPermissions.delete && selected.length > 0 && (
                          <div className="dropdown" id={styles.dropdown}>
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Actions
                            </button>
                            <ul
                              className="dropdown-menu"
                              id={styles.dropdown_menu}
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a className="dropdown-item" onClick={handleDelete}>
                                  Delete{' '}
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    id={styles.del_icon}
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="table-responsive" style={{ minHeight: '250px' }}>
                <table className="table table-borderless">
                  {columns.length > 0 && (
                    <thead>
                      <tr className={styles[tableheadStyle]}>
                        {/* {addButtonPermissions.delete && (
                          <th scope="col">
                            <input
                              type="checkbox"
                              id="custom-checkbox"
                              // name={data.id}
                              // value={data.id}
                              checked={checkedAll}
                              onChange={() => handleOnChangeAll()}
                            />
                          </th>
                        )} */}
                        {/* Table Heading From the display feilds view data  */}
                        {columns.map((item) => (
                          <th scope="col" className={styles.tableheading}>
                            {' '}
                            {tableConfig
                              ? tableConfig[item.db].alias.toUpperCase()
                              : item.view.toUpperCase()}
                            {tableConfig ? (
                              tableConfig[item.db].isSort && (
                                <button
                                  className={styles.sortbtn}
                                  type="button"
                                  aria-label="Sort button"
                                  onClick={setSort(item.db)}
                                >
                                  {sorting.sortType === 'asc' && sorting.col === item.db ? (
                                    <i className="fa fa-arrow-up" />
                                  ) : sorting.sortType === 'desc' && sorting.col === item.db ? (
                                    <i className="fa fa-arrow-down" />
                                  ) : (
                                    <i className="fa fa-sort" />
                                  )}
                                </button>
                              )
                            ) : (
                              <button
                                className={styles.sortbtn}
                                type="button"
                                aria-label="Sort button"
                                onClick={setSort(item.db)}
                              >
                                {sorting.sortType === 'asc' && sorting.col === item.db ? (
                                  <i className="fa fa-arrow-up" />
                                ) : sorting.sortType === 'desc' && sorting.col === item.db ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-sort" />
                                )}
                              </button>
                            )}
                          </th>
                        ))}
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                  )}
                  {/* Table Data mapping From the display feilds db data  as it where the db feild */}
                  {Object.keys(tableData) && !tableLoading ? (
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr className={styles[tablebodyStyle]}>
                          {/* {addButtonPermissions.delete && (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[index]}
                                  onChange={() => handleOnChange(item.id, index)}
                                />
                              </div>
                            </th>
                          )} */}
                          {columns.map((ele, i) => (
                            <td style={{ fontSize: '15px' }}>
                              {ele.db === toggleButton.field ? (
                                <ToggleButtonOnOff
                                  value={item[ele.db]}
                                  id={item.id}
                                  placeholders={['Active', 'Inactive']}
                                />
                              ) : typeof item[ele.db] === 'boolean' ? (
                                <h6 className="order_date"> String(item[ele.db])</h6>
                              ) : formatter && Object.keys(formatter).includes(ele.db) ? (
                                formatter[ele.db](item[ele.db])
                              ) : ele.db === 'location' ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item[ele.db],
                                  }}
                                />
                              ) : ele.db === 'eventName' ? (
                                <b>{item[ele.db]}</b>
                              ) : ele.db === 'time' ? (
                                formatTimeRange(item[ele.db])
                              ) : (
                                // ) : ele.db === 'status' ? (
                                //   <b>Completed</b>
                                item[ele.db]
                              )}
                            </td>
                          ))}
                          <td>
                            <EventActionButtons
                              data={item}
                              viewPopUp={viewPopUp}
                              section={section}
                              pageNum={pageNum}
                              setDeleteSuccess={setDeleteSuccess}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <SpinnerDotted
                      style={{
                        color: '#39979d',
                        left: '50%',
                        position: 'relative',
                        textAlign: 'center',
                        top: '50%',
                      }}
                    />
                  )}
                  {tableData.length === 0 && !tableLoading ? (
                    <div className={styles.norecordsdiv}>
                      <Image src={noresults} alt="" />
                      <h6 className={styles.norecord}>No Items to Display</h6>
                    </div>
                  ) : (
                    ''
                  )}
                </table>
              </div>
            </div>
            {!tableLoading && tableData.length !== 0 && (
              <Paginations
                paginationData={paginationData}
                setPageNum={setPageNum}
                dataName="Event"
                // maxPagesToShow={5}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

EventTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  formatter: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
  dashTitle: PropTypes.string,
};

EventTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: 'none' },
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
  section: 'Event',
  formatter: undefined,
  urlParam: null,
  dashTitle: undefined,
};

export default EventTable;
