import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import styles from './Pagination.module.scss';
import { TableContext } from '../../contexts/tableContext';

export default function Paginate({ paginationData, setPageNum, maxPagesToShow, dataName }) {
  const { bodyStyle } = useContext(TableContext);

  const startPage = Math.max(1, paginationData.page - Math.floor(maxPagesToShow / 2));
  const endPage = Math.min(paginationData.totalPages, startPage + maxPagesToShow - 1);
  const adjustedStartPage = Math.max(1, endPage - maxPagesToShow + 1);
  const paginationItems = Array.from(
    { length: endPage - adjustedStartPage + 1 },
    (_, index) => adjustedStartPage + index
  );

  const setPageNumber = (page, pageName) => () => {
    switch (pageName) {
      case 'goto':
        setPageNum(page);
        break;
      case 'next':
        setPageNum(page + 1);
        break;
      case 'previous':
        setPageNum(page - 1);
        break;
      case 'first':
        setPageNum(1);
        break;
      case 'last':
        setPageNum(paginationData.totalPages);
        break;
      default:
        setPageNum(1);
        break;
    }
  };

  return (
    <div className={`${styles[bodyStyle]} ${styles.paginations_bg}`}>
      <div className={styles.pageof}>
        Page {paginationData.page} of {paginationData.totalPages}
      </div>

      <Pagination className={styles.paginationm_container}>
        {/* FIRST PAGE BUTTON */}
        <Pagination.First
          disabled={paginationData.page === 1}
          onClick={setPageNumber(paginationData.page, 'first')}
        />

        {/* PREVIOUS PAGE BUTTON */}
        <Pagination.Item
          className={styles.pagination_next}
          disabled={paginationData.page <= 1}
          onClick={setPageNumber(paginationData.page, 'previous')}
        >
          PREV
        </Pagination.Item>

        {/* NUMBERED PAGE BUTTONS - MAPPED BASED ON 'maxPagesToShow' PROP */}
        {paginationItems.map((number) => (
          <Pagination.Item
            key={number}
            className={number === paginationData.page ? styles.active : ''}
            onClick={setPageNumber(number, 'goto')}
          >
            {number}
          </Pagination.Item>
        ))}

        {/* NEXT PAGE BUTTON */}
        <Pagination.Item
          className={styles.pagination_next}
          disabled={paginationData.page >= paginationData.totalPages}
          onClick={setPageNumber(paginationData.page, 'next')}
        >
          NEXT
        </Pagination.Item>

        {/* LAST PAGE BUTTON */}
        <Pagination.Last
          disabled={paginationData.page === paginationData.totalPages}
          onClick={setPageNumber(paginationData.page, 'last')}
        />
      </Pagination>
      <div className={styles.total_users}>
        Total <span>{paginationData.totalResult}</span>{' '}
        {paginationData.totalResult > 1 ? `${dataName}s` : dataName}
      </div>
    </div>
  );
}

Paginate.propTypes = {
  paginationData: PropTypes.oneOfType([PropTypes.any]).isRequired,
  setPageNum: PropTypes.func.isRequired,
  maxPagesToShow: PropTypes.number,
  dataName: PropTypes.string.isRequired,
};

Paginate.defaultProps = {
  maxPagesToShow: 10,
};
