/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { SpinnerDotted } from 'spinners-react';
import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TableContext } from '../../contexts/tableContext';
import ActionButtons from '../ActionsButtons/ActionButtons';
import { tabledelete } from '../../redux/TableDeleteSlice';
import ToggleButtonOnOff from '../ToggleButton/ToggleButton';
import styles from './CustomTable.module.scss';
import { getCookies } from '../../hooks/useCookies';
import searchImg from '../../assets/search.svg';
import Paginations from '../Pagination/Paginations';
import DeleteModal from '../DeleteModal/DeleteModal';
import EventActionButtons from '../ActionsButtons/EventActionButtons';
import noresults from '../../assets/noresults.svg';

const CustomTable = React.memo(
  ({ urlParam, viewPopUp, toggleButton, section, formatter, deleteCondition, dashTitle }) => {
    let url;
    const { dashboardStyle } = useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const { pageNumView, setPageNumView } = useContext(TableContext);
    const [pageNum, setPageNum] = useState(1);
    // new
    const tablelength = tableData.length;
    const [checkedState, setCheckedState] = useState(new Array(tablelength).fill(false));
    const [checkedAll, setCheckedAll] = useState(false);
    const token = `Bearer ${getCookies('Token')}`;
    const [sorting, setSorting] = useState([{ col: 'none', sortType: 'none' }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    const [tableConfig, setTableConfig] = useState();
    const [deletedData, setDeletedData] = useState(null);
    const [selected, setselected] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [deleteSuccess, setDeleteSuccess] = useState();
    const [showDeleteModal, setDeleteModal] = useState(false);
    const dataPerPage = 5;
    let response;
    let statusCode;

    const handleOnChange = (id, position) => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      const updatedCheckedState = checkedState.map((item, indexed) =>
        indexed === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      setselected((oldArray) => [...oldArray, id]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= checkedState.length; i++) {
        if (checkedState[position] === true) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < selected.length; j++) {
            if (selected[j] === id) {
              selected.splice(j, 1);
            }
          }
        }
      }
    };

    const actionAdd = () => {
      navigate(`/${section.toLowerCase()}/create/`);
    };

    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };

    const getUsers = async () => {
      setTableLoading(true);
      setLoading(false);
      url = urlParam;
      url += `page=${pageNumView > 1 ? pageNumView : pageNum}&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== 'none') {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        url += `&searchBy=${search}`;
      }
      axios
        .get(url, {
          method: 'GET',
          headers: { Authorization: token },
        })
        .then((resp) => {
          setTableLoading(false);
          statusCode = resp.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200) {
            response = res.data.data.result;
            setTableData(response.results);
            setPaginationData({
              page: response.page,
              totalResult: response.totalResults,
              totalPages: response.totalPages,
            });
            setPageNum(response.page);
            const { Displayfields } = response;
            if (Array.isArray(Displayfields)) {
              setColumns(Displayfields);
            } else {
              const colkeys = Object.keys(Displayfields);
              setTableConfig(Displayfields);
              setColumns(colkeys);
            }
          }
        })
        .catch((err) => {
          setTableLoading(false);
          return err;
        });
      if (pageNumView > 1) setPageNumView(1);
    };

    const truncateText = (text, maxLength = 20) => {
      if (typeof text !== 'string') return text;
      return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    };

    const confirmDelete = () => {
      const actionData = {};
      actionData.actionUrl = section.toLowerCase();
      actionData.apiData = selected;
      dispatch(tabledelete(actionData)).then(() => {
        toast.success('Rows deleted successfully.');
        setDeletedData(`data deleted ${selected}`);
        setDeleteModal(false);
      });
    };

    const handleDelete = () => setDeleteModal(true);
    const handleDeleteClose = () => setDeleteModal(false);

    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, []);

    useEffect(() => {
      const temp = section.replace(/\s+/g, '').toLowerCase();
      const permissions = getCookies('USERPERMISSION');
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              setAddButtonPermissions({
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(element.view || element.edit || element.create || element.delete),
              });
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    }, [pageNum, search, sorting, deletedData, deleteSuccess]);

    const setSort = (param) => () => {
      setPageNum(1);
      switch (sorting.sortType) {
        case 'none':
          setSorting({ col: param, sortType: 'asc' });
          break;
        case 'asc':
          setSorting({ col: param, sortType: 'desc' });
          break;
        case 'desc':
          setSorting({ col: param, sortType: 'none' });
          break;
        default:
          setSorting({ col: param, sortType: 'asc' });
          break;
      }
    };

    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.length > 0) {
            setPageNum(1);
            setSearchTerm(even.target.value.trim());
          } else {
            setSearchTerm(null);
          }
        }, 1000)
      );
    };

    return (
      <div>
        {/* DELETE MODAL */}
        <DeleteModal
          show={showDeleteModal}
          handleClose={handleDeleteClose}
          onConfirm={confirmDelete}
          onCancel={handleDeleteClose}
        />

        {addButtonPermissions.list && (
          <div className={styles[dashboardStyle]} style={{ minHeight: '100vh' }}>
            <div className="container-fluid" id={styles.container}>
              {!loading && (
                <div
                  className="table_top"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <h5>
                    <b>{dashTitle}</b>
                  </h5>
                  <div className="search_contain mb-20">
                    <Image src={searchImg} alt="search" />
                    <input
                      className={`${styles[searchboxStyle]} searchbar`}
                      type="search"
                      onChange={onFilterTextChange}
                      placeholder={
                        section === 'attendee'
                          ? 'Search by Name, Email or Phone'
                          : section === 'contest' || section === 'past-contest'
                          ? 'Search by Contest Name'
                          : section === 'manager'
                          ? 'Search by Event manager Name, Business Name, Email or Phone number'
                          : section === 'vendorusers'
                          ? 'Search by Vendor Name, Stall Name, Email or Phone number'
                          : section === 'audit'
                          ? 'Search by Name or Email'
                          : section === 'reported' || section === 'concluded'
                          ? 'Search by Title, Category, Reported By'
                          : 'Search'
                      }
                      readOnly={tableLoading}
                    />
                    <div
                      className={tableLoading ? 'spinner-border spinner-border-sm' : ''}
                      id={styles.spinner}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="table-responsive" style={{ minHeight: '250px' }}>
                <table>
                  {columns.length > 0 && (
                    <thead>
                      <tr className={styles[tableheadStyle]}>
                        {/* Table Heading From the display feilds view data  */}
                        <th className={styles.sl_fld} scope="col">
                          SL
                        </th>
                        {columns.map((item) => (
                          <th scope="col">
                            {' '}
                            {tableConfig
                              ? tableConfig[item.db].alias.toUpperCase()
                              : item.view.toUpperCase()}
                            {tableConfig ? (
                              tableConfig[item.db].isSort && (
                                <button
                                  className={styles.sortbtn}
                                  type="button"
                                  aria-label="Sort button"
                                  onClick={setSort(item.db)}
                                >
                                  {sorting.sortType === 'asc' && sorting.col === item.db ? (
                                    <i className="fa fa-arrow-up" />
                                  ) : sorting.sortType === 'desc' && sorting.col === item.db ? (
                                    <i className="fa fa-arrow-down" />
                                  ) : (
                                    <i className="fa fa-sort" />
                                  )}
                                </button>
                              )
                            ) : (
                              <button
                                className={styles.sortbtn}
                                type="button"
                                aria-label="Sort button"
                                onClick={setSort(item.db)}
                              >
                                {sorting.sortType === 'asc' && sorting.col === item.db ? (
                                  <i className="fa fa-arrow-up" />
                                ) : sorting.sortType === 'desc' && sorting.col === item.db ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-sort" />
                                )}
                              </button>
                            )}
                          </th>
                        ))}
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                  )}
                  {/* Table Data mapping From the display feilds db data  as it where the db feild */}
                  {Object.keys(tableData) && !tableLoading ? (
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr className={styles[tablebodyStyle]}>
                          <td>
                            <b>{index < 9 ? `0${index + 1}` : index + 1}</b>
                          </td>
                          {/* {addButtonPermissions.delete && (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[index]}
                                  onChange={() => handleOnChange(item.id, index)}
                                />
                              </div>
                            </th>
                          )} */}
                          {columns.map((ele) => (
                            <td>
                              {ele.db === toggleButton.field ? (
                                <ToggleButtonOnOff
                                  value={item[ele.db]}
                                  section={section}
                                  id={item.id}
                                  placeholders={['Active', 'Inactive']}
                                />
                              ) : typeof item[ele.db] === 'boolean' ? (
                                <h6 className="order_date"> String(item[ele.db])</h6>
                              ) : formatter && Object.keys(formatter).includes(ele.db) ? (
                                formatter[ele.db](item[ele.db])
                              ) : ele.db === 'location' ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item[ele.db],
                                  }}
                                />
                              ) : ele.db === 'eventName' ? (
                                <b>{item[ele.db]}</b>
                              ) : ele.db === 'email' ? (
                                truncateText(item[ele.db])
                              ) : ele.db === 'wallet' ? (
                                <b>{item[ele.db].tokensLeft}</b>
                              ) : (
                                item[ele.db]
                              )}
                            </td>
                          ))}
                          <td>
                            {section === 'vendor' ? (
                              <EventActionButtons
                                data={item}
                                viewPopUp={viewPopUp}
                                section={section}
                                pageNum={pageNum}
                                setDeleteSuccess={setDeleteSuccess}
                              />
                            ) : (
                              <ActionButtons
                                data={item}
                                viewPopUp={viewPopUp}
                                section={section}
                                pageNum={pageNum}
                                setDeleteSuccess={setDeleteSuccess}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <SpinnerDotted
                      style={{
                        color: '#39979d',
                        left: '50%',
                        position: 'relative',
                        textAlign: 'center',
                        top: '50%',
                      }}
                    />
                  )}
                  {tableData.length === 0 && !tableLoading ? (
                    <div className={styles.norecordsdiv}>
                      <Image src={noresults} alt="" />
                      <h6 className={styles.norecord}>No Items to Display</h6>
                    </div>
                  ) : (
                    ''
                  )}
                </table>
              </div>
            </div>
            {!tableLoading && tableData.length !== 0 && (
              <Paginations
                paginationData={paginationData}
                setPageNum={setPageNum}
                // maxPagesToShow={5}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

CustomTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  formatter: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
  dashTitle: PropTypes.string,
};

CustomTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: 'none' },
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
  section: 'User',
  formatter: undefined,
  urlParam: null,
  dashTitle: undefined,
};

export default CustomTable;
