import * as yup from 'yup';

// Either define validations for each field and export them alone
// or you can define a group of validations and export them together like below.
// Defining validations as a group might be good if it is done for an entire form
const vendorValidations = {
  name: yup
    .string()
    .required('Vendor Name is required.')
    .min(3, 'Enter a name between 3 and 30 characters in length.')
    .max(30, 'Enter a name between 3 and 30 characters in length.')
    .matches(
      /^(?!.*\s{2,})(?!.*\s$).*$/,
      'The input should not contain extra spaces. Check for trailing or multiple spaces between words.'
    ),
  businessName: yup
    .string()
    .required('Stall Name is required.')
    .min(3, 'Enter a stall name between 3 and 50 characters in length.')
    .max(50, 'Enter a stall name between 3 and 50 characters in length.')
    .matches(
      /^(?!.*\s{2,})(?!.*\s$).*$/,
      'The input should not contain extra spaces. Check for trailing or multiple spaces between words.'
    ),
  category: yup.mixed().required('Vendor Category is required.'),
  email: yup
    .string()
    .required('Vendor Email is required.')
    .matches(
      /^(?!.*\.\.)(?!.*@{2,})(?!.*@.*@)(?!.*\.@)(?!@\.)(?!@-)(?!.*-$)[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{1,}$/,
      'Invalid email address format.'
    )
    .matches(
      /\.[a-zA-Z]{2,62}$/,
      'Top-level domain (TLD) must be at least 2 characters and a maximum of 62 characters long.'
    ),
  phone: yup
    .string()
    .required('Contact number is required.')
    .max(14, 'Enter a valid contact number with 14 characters in length.'),
  vendorImage: yup
    .array()
    .of(
      yup
        .mixed()
        .test('fileSize', 'File too large.', (value) => value.size <= 10 * 1024 * 1024)
        .test(
          'fileType',
          `The file you selected appears to be corrupted. Please upload files in jpg, jpeg or png formats only.`,
          (value) => {
            const validTypes = ['image/jpeg', 'image/png'];
            return validTypes.includes(value.type);
          }
        )
        .test('isCorrupted', 'The file you selected appears to be corrupted.', async (value) => {
          const reader = new FileReader();
          return new Promise((resolve) => {
            reader.onload = () => {
              const img = new Image();
              img.src = reader.result;
              img.onload = () => resolve(true);
              img.onerror = () => resolve(false);
            };
            reader.readAsDataURL(value);
          });
        })
    )
    .max(10, 'Maximum 1 file is allowed.'),
};

export default vendorValidations;
