/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import axios from 'axios';
import styles from './CustomFileUpload.module.scss';
import envValues from '../../enviornment';
import { getCookies } from '../../hooks/useCookies';

function CustomFileUpload({
  itemId,
  isMultiple,
  errors = {},
  onFilesChange,
  trigger,
  initialFiles = [],
  title,
  disabled,
  section,
}) {
  const [edited, setEdited] = useState(initialFiles);
  const [uploadedImgs, setUplodedImgs] = useState([]);
  const token = getCookies('Token');
  const pdfImg =
    'https://thesoftwarepro.com/wp-content/uploads/2019/12/microsoft-office-pdf-document-953x1024.jpg';
  const videoImg =
    'https://image.shutterstock.com/image-vector/play-button-icon-vector-illustration-260nw-1697833306.jpg';
  const audioImg = 'https://cdn.pixabay.com/photo/2017/11/10/05/34/sound-2935466_1280.png';

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setUplodedImgs([...edited, ...selectedFiles]);
    onFilesChange([...edited, ...selectedFiles]);
    trigger('fileInput');
  };

  const handleCancel = (index) => {
    const updatedFiles = edited.filter((_, i) => i !== index);
    setUplodedImgs(updatedFiles);
    onFilesChange(updatedFiles);
    trigger('fileInput');
  };

  const renderFilePreview = (file, index) => {
    let imageSrc;
    if (typeof file === 'string') {
      imageSrc = file;
    } else if (file.type.startsWith('image/')) {
      imageSrc = URL.createObjectURL(file);
    } else if (file.type.startsWith('video/')) {
      imageSrc = videoImg;
    } else if (file.type.startsWith('audio/')) {
      imageSrc = audioImg;
    } else {
      imageSrc = pdfImg;
    }
    return (
      <div key={index} className={styles.uploaded_imgsection}>
        <img
          id={styles.uploaded_img}
          src={imageSrc}
          alt="preview"
          className="img-thumbnail img-fluid uploaded-img mr-2"
        />
      </div>
    );
  };

  const handleRemoveButton = () => {
    axios
      .delete(`${envValues.REACT_APP_API_ENDPOINT}/${section}/imageremove/${itemId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setEdited(res.data.data.eventData.image);
        toast.success('Image removed!');
      });
  };
  return (
    <div>
      <div className="d-flex">
        <div className="d-flex">
          <div className="file-uploader-mask d-flex justify-content-center align-items-center" />
          <input
            id="upload"
            className="file-input"
            type="file"
            multiple={isMultiple}
            onChange={handleFileChange}
            disabled={disabled}
            hidden
          />
          <label htmlFor="upload" className={styles.upload_btn}>
            {title}
          </label>
        </div>
      </div>
      {errors.message && (
        <p
          style={{
            color: 'red',
            marginTop: '5px',
            fontSize: '14px',
          }}
        >
          {errors.message}
        </p>
      )}
      <div className="d-flex flex-wrap mt-4">
        {Array.isArray(edited) &&
          edited.map((uploadedImg, index) => (
            <div className={styles.close_icon_main}>
              <img
                src={uploadedImg}
                key={uploadedImg}
                style={{ height: 100, width: 100, objectFit: 'contain' }}
                alt="UploadedImages"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = uploadedImg.extension === 'video' ? videoImg : pdfImg;
                }}
                className="img-thumbnail img-fluid uploaded-img mr-5"
              />
              <button
                type="button"
                className={styles.close_icon}
                onClick={() => handleRemoveButton()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 19 19"
                  stroke="#60677e"
                  strokeWidth="2"
                  className="react-date-picker__clear-button__icon react-date-picker__button__icon"
                  style={{ marginTop: '-10px', marginLeft: '-3px' }}
                >
                  <line x1="4" x2="15" y1="4" y2="15" />
                  <line x1="15" x2="4" y1="4" y2="15" />
                </svg>
              </button>
            </div>
          ))}
      </div>
      <div className="image upload mt-2">
        {uploadedImgs.map((file, index) => (
          <div key={file?.name}>
            {renderFilePreview(file, index)}
            <button
              type="button"
              onClick={() => handleCancel(index)}
              className={styles.imgcancel_btn}
              disabled={disabled}
            >
              Remove
            </button>
            {errors && errors.length > 0 && (
              <div>
                <p
                  key={new Date().toISOString()}
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    fontSize: '14px',
                  }}
                >
                  {errors[index]?.message}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

CustomFileUpload.propTypes = {
  isMultiple: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
  onFilesChange: PropTypes.func.isRequired,
  initialFiles: PropTypes.array,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  section: PropTypes.string,
  itemId: PropTypes.string,
};

CustomFileUpload.defaultProps = {
  initialFiles: [],
  title: 'Choose File',
  disabled: false,
  section: 'user',
  itemId: '',
};

export default CustomFileUpload;
