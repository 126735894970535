/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { Label } from 'reactstrap';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './popUp.module.scss';
import TextInput from '../TextField/TextField';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import SelectBox from '../SelectBox/SelectBox';
import PhoneNumberInput from '../PhoneNumberBox/PhoneNumberBox';
import EmailInput from '../EmailBox/EmailBox';
import CustomFileUpload from '../CustomFileUpload/CustomFileUpload';
import PhoneInputBox from '../PhoneNumberBox/PhoneInput';
import vendorValidations from '../../pages/vendors/validations';

function PopUp({ show, handleClose, onCancel, setData }) {
  const [submiting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const { errormsgStyle, formthemeStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const formSchema = object().shape({
    name: vendorValidations.name,
    email: vendorValidations.email,
    businessName: vendorValidations.businessName,
    phone: vendorValidations.phone,
    vendorImage: vendorValidations.vendorImage,
    category: vendorValidations.category,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const categories = [
    { label: 'Registration', value: 'Registration' },
    { label: 'Parking', value: 'Parking' },
    { label: 'Food Stalls', value: 'Food Stalls' },
    { label: 'Beverage Stalls', value: 'Beverage Stalls' },
    { label: 'Games', value: 'Games' },
    { label: 'Rides', value: 'Rides' },
    { label: 'Info Booths', value: 'Info Booths' },
    { label: 'Child Care', value: 'Child Care' },
    { label: 'Donation stalls', value: 'Donation stalls' },
    { label: 'Stage', value: 'Stage' },
    { label: 'Raffles/Draws', value: 'Raffles/Draws' },
    { label: 'Food & Beverages', value: 'Food & Beverages' },
    { label: 'Arts & Crafts', value: 'Arts & Crafts' },
    { label: 'Clothing & Accessories', value: 'Clothing & Accessories' },
    { label: 'Health & Wellness', value: 'Health & Wellness' },
    { label: 'Home & Garden', value: 'Home & Garden' },
    { label: 'Technology & Gadgets', value: 'Technology & Gadgets' },
    { label: 'Toys & Games', value: 'Toys & Games' },
    { label: 'Books & Stationery', value: 'Books & Stationery' },
    { label: 'Beauty & Personal Care', value: 'Beauty & Personal Care' },
    { label: 'Entertainment & Activities', value: 'Entertainment & Activities' },
  ];
  const {
    control,
    register,
    handleSubmit,
    trigger,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });
  const handleFilesChange = (file) => {
    setValue('vendorImage', file);
  };

  const pdfImg =
    'https://thesoftwarepro.com/wp-content/uploads/2019/12/microsoft-office-pdf-document-953x1024.jpg';
  const videoImg =
    'https://image.shutterstock.com/image-vector/play-button-icon-vector-illustration-260nw-1697833306.jpg';
  const audioImg = 'https://cdn.pixabay.com/photo/2017/11/10/05/34/sound-2935466_1280.png';

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...files, ...selectedFiles]);
    handleFilesChange([...files, ...selectedFiles]);
    trigger('vendorImage');
  };

  const handleCancel = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    handleFilesChange(updatedFiles);
    trigger('vendorImage');
  };

  const renderFilePreview = (file, index) => {
    let imageSrc;
    if (file.type.startsWith('image/')) {
      imageSrc = URL.createObjectURL(file);
    } else if (file.type.startsWith('video/')) {
      imageSrc = videoImg;
    } else if (file.type.startsWith('audio/')) {
      imageSrc = audioImg;
    } else {
      imageSrc = pdfImg;
    }
    return (
      <div key={index} className={styles.uploaded_imgsection}>
        <img
          id={styles.uploaded_img}
          src={imageSrc}
          alt="preview"
          className="img-thumbnail img-fluid uploaded-img mr-2"
        />
      </div>
    );
  };
  const handlePhoneChange = (number) => {
    setValue('phone', number);
    trigger('phone');
  };
  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      businessName: data.businessName,
      email: data.email,
      phone: data.phone,
      category: data.category.value,
      userType: 'vendor',
      isVerified: true,
      File: data.vendorImage,
    };
    data.actionUrl = 'vendor';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data))
      .then((resp) => {
        setSubmitting(false);
        if (resp?.payload?.code === 200) {
          toast.success('Vendor created successfully.');
          setData(resp?.payload?.data?.user?.id);
          setFiles([]);
          onCancel();
          reset();
        } else if (resp?.payload?.code === 401 || resp?.payload?.code === 400) {
          setError(resp?.payload?.message);
        } else {
          setError('Something went wrong!');
        }
      })
      .catch((e) => toast.error('Something went wrong!!'));
  }
  return (
    <Modal show={show} onHide={handleClose} className="larg_model" centered>
      <Modal.Header>
        <div className="col-md-12">
          <div className={styles.pop_head}>
            <h4>Add New Vendor </h4>
            <Button
              className={styles.pop_close}
              onClick={(e) => {
                onCancel(e);
                reset();
                setFiles([]);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
              >
                <path
                  d="M9.33398 10.1672L22.6673 23.5006M9.33398 23.5006L22.6673 10.1672"
                  stroke="#FF0000"
                  strokeWidth="2.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="px-0 pb-0">
        <form onSubmit={handleSubmit(onSubmitdata)}>
          <div className={`${styles.formthemeStylesec} form_container`} id={styles.addform}>
            <div className="container-fluid p-0">
              <div className={styles.pop_cnt}>
                <div className="row">
                  <div className="col-md-6">
                    <div className={styles.from_group}>
                      <TextInput
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        labelstyle={styles.formlabel}
                        label="Stall Name"
                        fieldName="businessName"
                        placeHolder="Enter Stall Name"
                        register={register}
                        errors={errors}
                        isRequired
                        mandatory
                      />
                    </div>
                    <div className={`${styles.from_group} selectDrp`}>
                      <SelectBox
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        name="category"
                        label="Vendor Category"
                        labelstyle={styles.formlabel}
                        control={control}
                        register={register}
                        values={categories}
                        errors={errors}
                        placeholder="Select Vendor Category"
                        mandatory
                      />
                    </div>
                    <div className={styles.from_group}>
                      <Label className={styles.formlabel}>Vendor Image</Label>
                      {/* <span style={{ color: 'red', marginLeft: '3px' }}>*</span> */}
                      <div className={styles.image_drag}>
                        <div className={styles.image_drag_inner}>
                          <label htmlFor="vendorUpload" style={{ cursor: 'pointer' }}>
                            <h6>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                fill="none"
                              >
                                <path
                                  d="M13.3617 16.8441C14.3674 16.8441 15.1694 16.0293 15.1694 15.0363C15.1694 14.0306 14.3674 13.2158 13.3617 13.2158C12.3687 13.2158 11.554 14.0306 11.554 15.0363C11.554 16.0293 12.3687 16.8441 13.3617 16.8441Z"
                                  fill="#CBCBCB"
                                />
                                <path
                                  d="M16.1504 22.2547L21.4717 18.6137C25.5964 15.7876 31.3379 15.813 35.4244 18.6774L37.7796 20.3196V9.81694C37.7796 7.7546 36.0991 6.07416 34.0368 6.07416H6.92076C4.85842 6.07416 3.17798 7.7546 3.17798 9.81694V21.8727L7.25176 23.324C10.2307 24.3934 13.6424 23.9733 16.1504 22.2547ZM13.3624 10.6699C15.7685 10.6699 17.7162 12.6304 17.7162 15.0364C17.7162 17.4298 15.7685 19.3903 13.3624 19.3903C10.9563 19.3903 9.00858 17.4298 9.00858 15.0364C9.00858 12.6304 10.9563 10.6699 13.3624 10.6699Z"
                                  fill="#CBCBCB"
                                />
                                <path
                                  d="M6.92076 34.5139H30.2176V34.1957C29.3647 34.1193 28.5244 33.7756 27.8625 33.1263C26.3603 31.6495 26.3348 29.2308 27.8115 27.7286L31.3124 24.1513L31.3252 24.1386C32.0127 23.4384 32.9801 22.9928 34.0368 22.9928C35.1062 22.9928 36.0737 23.4384 36.7611 24.1386L36.7739 24.1513L37.7796 25.1825V23.4257L33.9731 20.765C30.7778 18.5371 26.1312 18.5117 22.9103 20.7141L17.589 24.355C15.5775 25.7299 13.1205 26.4428 10.6508 26.4428C9.21224 26.4428 7.77368 26.2137 6.39884 25.7172L3.17798 24.5714V30.7839C3.17798 32.8462 4.85842 34.5139 6.92076 34.5139Z"
                                  fill="#CBCBCB"
                                />
                                <path
                                  d="M34.042 36.0123C34.745 36.0123 35.315 35.4422 35.315 34.7392V29.9386L36.6403 31.2911C37.132 31.7934 37.9388 31.8002 38.4405 31.3098C38.9427 30.8175 38.9508 30.0119 38.4591 29.5096L34.9514 25.9298C34.951 25.9294 34.9503 25.9292 34.9499 25.9288C34.7188 25.6926 34.3985 25.5444 34.042 25.5444C33.6854 25.5444 33.3651 25.6926 33.134 25.9288C33.1336 25.9292 33.1329 25.9294 33.1326 25.9298L29.6254 29.5096C29.1337 30.0119 29.1418 30.8175 29.6441 31.3098C29.8915 31.5522 30.2135 31.6734 30.5349 31.6734C30.8649 31.6734 31.195 31.546 31.4443 31.2911L32.7689 29.9389V34.7392C32.7689 35.4422 33.3389 36.0123 34.042 36.0123Z"
                                  fill="#CBCBCB"
                                />
                              </svg>
                            </h6>
                            <h5>Upload Image</h5>
                          </label>
                          <input
                            id="vendorUpload"
                            className="file-input"
                            type="file"
                            onChange={handleFileChange}
                            hidden
                          />
                        </div>
                      </div>
                      {errors.message && (
                        <p
                          style={{
                            color: 'red',
                            marginTop: '5px',
                            fontSize: '14px',
                          }}
                        >
                          {errors.message}
                        </p>
                      )}
                      <div className="image upload mt-2">
                        {files.map((file, index) => (
                          <div key={file?.name}>
                            {renderFilePreview(file, index)}
                            <button
                              type="button"
                              onClick={() => handleCancel(index)}
                              className={styles.imgcancel_btn}
                            >
                              Remove
                            </button>
                            {errors?.vendorImage && errors?.vendorImage.length > 0 && (
                              <div>
                                <p
                                  key={new Date().toISOString()}
                                  style={{
                                    color: 'red',
                                    marginTop: '5px',
                                    fontSize: '14px',
                                  }}
                                >
                                  {errors.vendorImage[index]?.message}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={styles.from_group}>
                      <TextInput
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        labelstyle={styles.formlabel}
                        label="Vendor Name"
                        fieldName="name"
                        placeHolder="Enter Vendor Name"
                        register={register}
                        errors={errors}
                        isRequired
                        mandatory
                      />
                    </div>
                    <div className={styles.from_group}>
                      <EmailInput
                        fieldName="email"
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        labelstyle={styles.formlabel}
                        label="Email"
                        register={register}
                        errors={errors}
                        placeHolder="Enter Email Address"
                        isRequired
                        mandatory
                      />
                    </div>
                    <div className={styles.from_group}>
                      <PhoneInputBox
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        labelstyle={styles.formlabel}
                        label="Contact Number"
                        placeHolder="Enter Contact Number"
                        fieldName="phone"
                        control={control}
                        errors={errors}
                        handleChange={handlePhoneChange}
                        mandatory
                        isRequired
                      />
                    </div>
                    <div className={styles.from_groupone}>
                      <Button
                        className="addBtn"
                        type="submit"
                        disabled={submiting}
                        value={submiting ? 'Please wait..' : 'Update'}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                </div>
                {error && (
                  <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                    {error}
                  </h6>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

PopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
};

// PopUp.defaultProps = {
//   data: '',
//   phone: '',
// };

export default PopUp;
